import { Link } from 'react-router-dom'
import styles from '../../styles/ErrorPage/ErrorPage.module.css'
const ErrorPage = () => {
    return (
        <div className={styles.div_error}>
            <img src="/assets/images/Banner_ErrorPage.jpg" className={styles.img_banner} alt='warning' />
            <div className={styles.title}>
                4<span>0</span>4
            </div>
            <div className={styles.info}>
                Thông tin không tồn tại
            </div>
            <div className={styles.btn}>
                <Link to={'/'} reloadDocument>Quay về trang chủ</Link>
            </div>
        </div>
    )
}
export default ErrorPage
import { Carousel } from "antd"
import styles from '../../styles/HomePage/slider.module.css'
import { useEffect, useState } from "react";
import { fetchData } from "../../utils/api";
import apiConfig from "../../constants/apiConfig"
const Slider = ({ isSlider, isShowTitle = false, title, content, imgUrl, style }) => {
    const [dataBanner, setDataBanner] = useState([]);

    useEffect(() => {
        const fetchDataAndSetState = async () => {
            try {
                const data = await fetchData(apiConfig.banner.path, apiConfig.banner.requestOption);
                setDataBanner(data.data);
            } catch (error) {
                console.error(error);
            }
        };

        if (isSlider) {
            fetchDataAndSetState();
        }
    }, [isSlider]);
    return (
        <div className={styles.container_slider}>
            {isSlider ? <Carousel autoplay>
                {dataBanner?.map((item) => {
                    return (
                        <div key={item.id} className={styles.img}>
                            <img src={item.imageUrl} alt={item.alt} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </div>
                    )
                })}
            </Carousel> :
                <div >
                    <img src={imgUrl} alt={"banner"} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </div>
            }

            {isShowTitle ?
                <div className={styles.title_banner}>
                    {
                        title ? <div className={style ? style : styles.about}>
                            {title}
                        </div> : null
                    }
                    {
                        content ? <div className={styles.we_are}>
                            {content}
                        </div> : null
                    }
                </div>
                : null
            }
        </div>
    )
}
export default Slider
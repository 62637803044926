import styles from '../../styles/Introduce/infoCompany.module.css'
import useDataCompany from '../../data/dataCompany'
const InfoCompany = () => {
    const dataCompany = useDataCompany();
    return (
        <div>

            <div key={dataCompany.id} className={styles.container_info}>
                <img src={dataCompany.image} alt='imgInfo' />
                <div style={{ display: 'flex', flexDirection: 'column' }} className={styles.div_content}>
                    <div className={styles.about}>
                        Về chúng tôi
                    </div>
                    <div className={styles.we_are}>
                        Tổng quan
                    </div>
                    <div className={styles.info_company}>
                        <div className={styles.div_info}>
                            <div className={styles.title}>Tên công ty</div>
                            <div className={styles.detail}> {dataCompany.fullName}</div>
                        </div>
                        <div className={styles.div_info}>
                            <div className={styles.title}>Tên viết tắt</div>
                            <div className={styles.detail}> {dataCompany.shortName}</div>
                        </div>
                        <div className={styles.div_info}>
                            <div className={styles.title}>Mã số thuế</div>
                            <div className={styles.detail}> {dataCompany.taxCode}</div>
                        </div>
                        <div className={styles.div_info}>
                            <div className={styles.title}>Địa chỉ</div>
                            <div className={styles.detail}> {dataCompany.address}</div>
                        </div>
                        <div className={styles.div_info}>
                            <div className={styles.title}>Số điện thoại</div>
                            <div className={styles.detail}> {dataCompany.phone}</div>
                        </div>
                        <div className={styles.div_info}>
                            <div className={styles.title}>WebSite</div>
                            <div className={styles.detail}> {dataCompany.website}</div>
                        </div>
                    </div>
                    <div className={styles.btn}>
                        Brochure
                    </div>
                </div>
            </div>
        </div>
    )
}
export default InfoCompany
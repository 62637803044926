import useDataBusinessArea from '../../data/dataBusinessArea'
import styles from '../../styles/Introduce/fieldOfActivity.module.css'
import BusinessAreaPagination from './paginationBusinessArea';
const FieldOfActivity = () => {
    const data = useDataBusinessArea()
    return (
        <div className={styles.container_fieldActivity}>
            <div className={styles.div_item}>
                <BusinessAreaPagination data={data} />
            </div>
        </div>
    )
}
export default FieldOfActivity
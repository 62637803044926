import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import styles from '../../styles/HomePage/project.module.css'
import useDataProject from '../../data/dataProject';
import apiConfig from '../../constants/apiConfig';

const ProjectContainer = ({ isShowBtn = true, title }) => {
    const [viewportWidth, setViewportWidth] = useState();
    const [itemProject, setItemProject] = useState(viewportWidth < 1226 ? 4 : 5);
    const dataProject = useDataProject(apiConfig.project.featuredProject.path, apiConfig.project.featuredProject.requestOption);
    useEffect(() => {
        const updateViewportWidth = () => {
            const width = window.innerWidth;
            setViewportWidth(width);
            setItemProject(width <= 1536 ? 4 : 5);
        };
        updateViewportWidth();
        window.addEventListener('resize', updateViewportWidth);
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, [viewportWidth]);

    return (
        <div className={styles.div_project}>
            <div className={styles.bgr_project}>
            </div>
            <div className={styles.project}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.container_project}>
                    {dataProject.slice(0, itemProject).map((item) => (
                        <div key={item.id} className={styles.container_infoProject}>
                            <div className={styles.overlay} >
                                <Link to={`/detailProject/${item.id}`} reloadDocument >
                                </Link>
                            </div>
                            <img src={item.avatarImage} alt="imgProject" className={styles.imgProject} />
                            <div className={styles.info}>
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.address}>{item.location}</div>
                            </div>
                        </div>
                    ))}
                </div>
                {
                    isShowBtn ? <div className={styles.btn_seeMore}>
                        <Link to={"/project"} reloadDocument  ><div>Xem thêm</div></Link>
                    </div> :
                        null
                }
            </div>
        </div>
    )
}
export default ProjectContainer
import { Link, useParams } from 'react-router-dom';
import styles from '../../styles/DetailProject/index.module.css'
import Slider from '../HomePage/slider'
import CarouselImg from './carouselImg';
import apiConfig from '../../constants/apiConfig';
import useDataDetail from '../../data/dataDetail';
import dayjs from 'dayjs';
const DetailProject = () => {
    const { projectId } = useParams();
    const detailData = useDataDetail(apiConfig.project.detailProject.path.replace(':id', projectId), apiConfig.project.detailProject.requestOption);
    return (
        <div className={styles.container_detailProject}>
            <Slider
                isShowTitle={true}
                isSlider={false}
                imgUrl={'/assets/images/banner_introduce.jpg'}
                title={'Chi tiết dự án'}
                content={'Dự án của chúng tôi'}
            />
            <div className={styles.div_Project}>
                {detailData ?
                    <div className={styles.infoProject} key={detailData.id}>
                        <div className={styles.info}>
                            <div className={styles.img_info}>
                                <img src={detailData.detailImage} alt='banner' style={{ width: '70%', height: '100%', objectFit: 'contain' }} />
                            </div>
                            <div className={styles.content}>
                                <span>Thông tin dự án</span>
                                <div className={styles.content_info}>
                                    <div className={styles.name}>
                                        <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
                                            <circle cx="25" cy="25.5" r="25" fill="url(#paint0_linear_184_157)" />
                                            <path d="M12.9043 18.96H37.0948V31.2457H12.9043V18.96Z" stroke="black" strokeWidth="2" />
                                            <circle cx="17.0642" cy="22.3249" r="1.48413" stroke="black" />
                                            <path d="M19.3407 27.2858C19.3407 27.4225 19.3306 27.5094 19.3205 27.5621C19.2841 27.5548 19.2365 27.5428 19.1752 27.5238C19.0666 27.4901 18.9437 27.444 18.7926 27.387L18.7807 27.3825C18.6374 27.3284 18.474 27.2667 18.301 27.2096C17.9458 27.0922 17.5227 26.9842 17.0629 26.9842C16.6032 26.9842 16.1801 27.0922 15.8248 27.2096C15.6519 27.2667 15.4885 27.3284 15.3452 27.3825L15.3333 27.387C15.1822 27.444 15.0592 27.4901 14.9507 27.5238C14.8894 27.5428 14.8418 27.5548 14.8053 27.5621C14.7953 27.5094 14.7852 27.4225 14.7852 27.2858C14.7852 25.8851 15.8369 24.8096 17.0629 24.8096C18.289 24.8096 19.3407 25.8851 19.3407 27.2858Z" stroke="black" />
                                            <line x1="23.0156" y1="22.2227" x2="34.1267" y2="22.2227" stroke="black" />
                                            <line x1="23.0156" y1="24.6035" x2="34.1267" y2="24.6035" stroke="black" />
                                            <line x1="23.0156" y1="26.9844" x2="34.1267" y2="26.9844" stroke="black" />
                                            <defs>
                                                <linearGradient id="paint0_linear_184_157" x1="-1.41753" y1="11.3333" x2="61.4829" y2="15.8002" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FFB629" />
                                                    <stop offset="0.507189" stopColor="#FFDA56" />
                                                    <stop offset="1" stopColor="#FFD7A6" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        </div>
                                        <div>{detailData.name}</div>
                                    </div>
                                    <div className={styles.address}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
                                            <circle cx="25" cy="25.5" r="25" fill="url(#paint0_linear_184_170)" />
                                            <path d="M24.2062 27.4847C26.1786 27.4847 27.7776 25.8857 27.7776 23.9132C27.7776 21.9408 26.1786 20.3418 24.2062 20.3418C22.2337 20.3418 20.6348 21.9408 20.6348 23.9132C20.6348 25.8857 22.2337 27.4847 24.2062 27.4847Z" stroke="#1C1F35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M24.6015 13.9922C32.1405 13.9922 35.7119 20.3414 32.9343 25.897C30.1981 31.3698 26.982 34.6272 24.6011 37.8018C22.6169 35.4208 18.2519 30.6589 15.8708 25.5002C12.2995 15.1827 21.823 13.9922 24.6015 13.9922Z" stroke="#1C1F35" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_184_170" x1="-1.41753" y1="11.3333" x2="61.4829" y2="15.8002" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FFB629" />
                                                    <stop offset="0.507189" stopColor="#FFDA56" />
                                                    <stop offset="1" stopColor="#FFD7A6" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        {detailData.location}
                                    </div>
                                    <div className={styles.date}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
                                            <circle cx="25" cy="25.5" r="25" fill="url(#paint0_linear_184_180)" />
                                            <rect x="13.6982" y="15.7861" width="23.3968" height="17.8413" stroke="black" strokeWidth="2" />
                                            <line x1="12.6982" y1="19.0479" x2="38.0951" y2="19.0479" stroke="black" />
                                            <line x1="19.0479" y1="22.2227" x2="33.3336" y2="22.2227" stroke="black" />
                                            <line x1="18.7539" y1="21.9287" x2="18.7539" y2="29.8652" stroke="black" />
                                            <line x1="23.5156" y1="21.9287" x2="23.5156" y2="29.8652" stroke="black" />
                                            <line x1="28.2773" y1="21.9287" x2="28.2773" y2="29.8652" stroke="black" />
                                            <line x1="33.0391" y1="21.9287" x2="33.0391" y2="29.8652" stroke="black" />
                                            <line x1="19.0479" y1="22.2227" x2="33.3336" y2="22.2227" stroke="black" />
                                            <line x1="19.0479" y1="24.6035" x2="33.3336" y2="24.6035" stroke="black" />
                                            <line x1="19.0479" y1="27.7773" x2="33.3336" y2="27.7773" stroke="black" />
                                            <line x1="18.2539" y1="30.1592" x2="33.3333" y2="30.1592" stroke="black" />
                                            <rect x="30.6582" y="13.6982" width="1.38095" height="2.96825" fill="#D9D9D9" stroke="black" />
                                            <rect x="17.9609" y="13.6982" width="1.38095" height="2.96825" fill="#D9D9D9" stroke="black" />
                                            <defs>
                                                <linearGradient id="paint0_linear_184_180" x1="-1.41753" y1="11.3333" x2="61.4829" y2="15.8002" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FFB629" />
                                                    <stop offset="0.507189" stopColor="#FFDA56" />
                                                    <stop offset="1" stopColor="#FFD7A6" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        {dayjs(detailData.launchDate).format('DD/MM/YYYY')}
                                    </div>
                                    <div className={styles.donors}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51" viewBox="0 0 50 51" fill="none">
                                            <circle cx="25" cy="25.5" r="25" fill="url(#paint0_linear_184_200)" />
                                            <path d="M12.9043 18.96H37.0948V31.2457H12.9043V18.96Z" stroke="black" strokeWidth="2" />
                                            <circle cx="21.032" cy="22.3249" r="1.48413" stroke="black" />
                                            <path d="M23.3095 27.449C23.3095 27.5996 23.2985 27.701 23.2852 27.7664C23.2512 27.7588 23.2073 27.7469 23.1513 27.7286C23.0434 27.6933 22.9209 27.6449 22.7698 27.5847L22.7577 27.5799C22.6145 27.5228 22.4508 27.4576 22.2775 27.3972C21.9216 27.2732 21.4956 27.1583 21.0317 27.1583C20.5678 27.1583 20.1418 27.2732 19.7859 27.3972C19.6126 27.4576 19.4489 27.5228 19.3057 27.5799L19.2936 27.5847C19.1424 27.6449 19.02 27.6933 18.9121 27.7286C18.8561 27.7469 18.8122 27.7588 18.7781 27.7664C18.7649 27.701 18.7539 27.5996 18.7539 27.449C18.7539 25.932 19.8295 24.8096 21.0317 24.8096C22.2339 24.8096 23.3095 25.932 23.3095 27.449Z" stroke="black" />
                                            <path d="M29.3647 21.1348H28.5711H27.7774H26.9838H26.587H26.1901H26.0966C25.9031 21.1348 25.7182 21.2149 25.5859 21.3561V21.3561C25.4642 21.4858 25.3965 21.657 25.3965 21.8349V21.9813V22.4046V22.8279V23.2512V23.3976C25.3965 23.5755 25.4642 23.7467 25.5859 23.8764V23.8764C25.7182 24.0176 25.9031 24.0977 26.0966 24.0977H26.1901H26.587H26.9838H27.3806H27.7774H28.5711H28.6646C28.8581 24.0977 29.043 24.1778 29.1754 24.319V24.319C29.297 24.4488 29.3647 24.62 29.3647 24.7979V24.9443V25.3676V25.7909V26.2141V26.6374V26.7838C29.3647 26.9617 29.297 27.1329 29.1754 27.2627V27.2627C29.043 27.4039 28.8581 27.484 28.6646 27.484H28.5711H27.7774H27.3806H26.9838H26.587H26.1901H25.7933H25.3965" stroke="black" />
                                            <path d="M27.7773 19.9443V29.0713" stroke="black" />
                                            <defs>
                                                <linearGradient id="paint0_linear_184_200" x1="-1.41753" y1="11.3333" x2="61.4829" y2="15.8002" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FFB629" />
                                                    <stop offset="0.507189" stopColor="#FFDA56" />
                                                    <stop offset="1" stopColor="#FFD7A6" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        {detailData.clientName}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.content_project} dangerouslySetInnerHTML={{ __html: detailData.content }}>
                        </div>
                        <div className={styles.titleImgProject}>Hình ảnh dự án</div>
                        <div className={styles.containerImg}>
                            <CarouselImg dataImg={detailData?.projectImages} />
                        </div>
                    </div> :
                    <div className={styles.project_notFound}>
                        <img src={'/assets/images/warning_icon.png'} alt='iconWarning' />
                        Dự án không tồn tại
                        <div className={styles.btn}>
                            <Link to={'/project'} reloadDocument>Quay về danh sách dự án</Link>
                        </div>
                    </div>}
            </div>
        </div>
    )
}
export default DetailProject

import React, { useState } from 'react';
import styles from '../../styles/Tabs/index.module.css'
// import PostNews from '../News/addNews';
const Tabs = ({ tabs, isPostNewItem = false }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index, idTab) => {
        setActiveTab(index);
    };

    return (
        <div>
            <div className={styles.tab_list}>
                <div className={styles.container_tabs}>
                    {tabs?.map((tab, index) => (
                        <div
                            key={tab.id}
                            className={`${styles.tab} ${index === activeTab ? `${styles.active}` : ''}`}
                            onClick={() => handleTabClick(index, tab.id)}
                        >
                            {tab?.label}
                        </div>
                    ))}
                </div>
            </div>
            {tabs[activeTab]?.content ? <div className={styles.tab_content}>{tabs[activeTab]?.content}</div> :
                <div className={styles.tab_content}>

                </div>
            }
            {/* {isPostNewItem ? <PostNews /> : null} */}
        </div>
    );
};

export default Tabs;

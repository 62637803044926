import { ApiUrl } from ".";

const baseHeader = {
    'Content-Type': 'application/json'
}

const multipartFormHeader = {
    'Content-Type': 'multipart/form-data'
}

const apiConfig = {

    media: {
        image: {
            path: `${ApiUrl}/api/files/upload`,
            requestOption: {
                method: 'POST',
                headers: multipartFormHeader
            }
        }
    },
    banner: {
        path: `${ApiUrl}/api/banner/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    project: {
        featuredProject: {
            path: `${ApiUrl}/api/projects/featured`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        listProject: {
            path: `${ApiUrl}/api/projects/all`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        detailProject: {
            path: `${ApiUrl}/api/projects/detail/:id`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        }
    },
    vision: {
        path: `${ApiUrl}/api/vision/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    coreValue: {
        path: `${ApiUrl}/api/core-value/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    customer: {
        path: `${ApiUrl}/api/partner/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    infoCompany: {
        path: `${ApiUrl}/api/company/detail`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    businessArea: {
        path: `${ApiUrl}/api/business-area/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    employee: {
        path: `${ApiUrl}/api/employee/all`,
        requestOption: {
            method: 'GET',
            headers: baseHeader
        }
    },
    news: {
        newsCategory: {
            path: `${ApiUrl}/api/news-categories/all`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        newsWithCategory: {
            path: `${ApiUrl}/api/news/category/:id`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        detailNews: {
            path: `${ApiUrl}/api/news/detail/:id`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        featuredNews: {
            path: `${ApiUrl}/api/news/featured`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        allNews: {
            path: `${ApiUrl}/api/news/all`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        },
        postComment: {
            path: `${ApiUrl}/api/comment/create`,
            requestOption: {
                method: 'POST',
                headers: baseHeader,
            }
        },
        getComment: {
            path: `${ApiUrl}/api/comment/news/:id`,
            requestOption: {
                method: 'GET',
                headers: baseHeader,
            }
        },
        postNews: {
            path: `${ApiUrl}/api/news/create`,
            requestOption: {
                method: 'POST',
                headers: baseHeader,
            }
        }
    },
    document: {
        getAll: {
            path: `${ApiUrl}/api/document/all`,
            requestOption: {
                method: 'GET',
                headers: baseHeader
            }
        }
    },
    candidate: {
        postCV: {
            path: `${ApiUrl}/api/candidate/create`,
            requestOption: {
                method: 'POST',
                headers: baseHeader
            }
        }
    },
}

export default apiConfig;
import { useEffect, useState } from "react";
import { fetchData } from "../utils/api";
import apiConfig from "../constants/apiConfig";

const useDataBusinessArea = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchDataAndSetState = async () => {
            try {
                const data = await fetchData(apiConfig.businessArea.path, apiConfig.businessArea.requestOption);
                setData(data.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDataAndSetState();
    }, []);
    return data
}
export default useDataBusinessArea

import styles from '../../styles/DocumentPage/index.module.css'
import Slider from '../HomePage/slider'
import PaginationDocument from './paginationDocument'
import useDataDetail from '../../data/dataDetail'
import apiConfig from '../../constants/apiConfig'
const DocumentPage = () => {
    const data = useDataDetail(apiConfig.document.getAll.path, apiConfig.document.getAll.requestOption)
    return (
        <div className={styles.div_document}>
            <Slider
                isShowTitle={true}
                isSlider={false}
                imgUrl={'/assets/images/banner_news.jpg'}
                title={''}
                content={''} />
            <div className={styles.title_container}>
                <div className={styles.title}>
                    Tài liệu
                </div>
                <div className={styles.content}>
                    Danh sách tài liệu
                </div>
                <PaginationDocument data={data} />
            </div>
        </div>
    )
}

export default DocumentPage
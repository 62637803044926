import styles from '../../styles/Introduce/staffList.module.css'
import CarouselStaff from '../Carousel'
const StaffList = () => {
    return (
        <div className={styles.container_staff_list}>
            <div className={styles.container}>
                <div className={styles.title}>
                    Nhân sự cao cấp tại BINCON
                </div>
                <CarouselStaff />
            </div>
        </div>
    )
}
export default StaffList
import { useEffect, useState } from "react";
import { fetchData } from "../utils/api";
import apiConfig from "../constants/apiConfig";

const useDataNewsWithCategory = (id) => {
    const [data, setData] = useState([]);
    const apiUrl = apiConfig.news.newsWithCategory.path.replace(':id', id);
    useEffect(() => {
        const fetchDataAndSetState = async () => {
            try {
                if (id) {
                    const data = await fetchData(apiUrl, apiConfig.news.newsWithCategory.requestOption);
                    setData(data.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchDataAndSetState();
    }, [id]);

    return data;
}
export default useDataNewsWithCategory
import React, { useState } from 'react';
import styles from '../../styles/News/addNews.module.css'
import apiConfig from '../../constants/apiConfig';
import { useForm } from 'react-hook-form';
import Editor from './editor';
import ModalThankYou from './modalThankyou';
const PostNews = (categoryNewsId) => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [urlFile, setUrlFile] = useState('')
    const [editorContent, setEditorContent] = useState('');
    const [refreshEditorKey, setRefreshEditorKey] = useState(0);
    const [openModal, setOpenModal] = useState(false)
    const onSubmit = (data) => {
        const requestBody = {
            newsCategoryId: categoryNewsId.categoryNewsId || categoryNewsId,
            title: data.title,
            content: editorContent,
            thumbnailImage: data.fileName,
            featured: false,
            source: 'web',
            status: 'NEW',
            author: data.name,
        };
        const requestOption = {
            ...apiConfig.news.postNews.requestOption,
            body: JSON.stringify(requestBody)
        }
        fetch(apiConfig.news.postNews.path, requestOption)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                reset();
                setSelectedFileName('');
                setUrlFile('')
                setRefreshEditorKey(prevKey => prevKey + 1);
                setOpenModal(true)
            })
            .catch(error => {
                console.error('Comment submission error:', error);
            });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setSelectedFileName(file ? file?.name : '');
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await fetch(apiConfig.media.image.path, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const fileLink = data.data;

            setValue('fileName', fileLink);
            setUrlFile(fileLink);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    const onClick = () => {
        setSelectedFileName('');
        setUrlFile('')
        setValue('fileName', '');
    }
    const onCloseModal = () => {
        setOpenModal(false)
    }
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form_PostNews}>
                <div className={styles.item_Recruitment}>
                    <label>Tiêu đề <span>(*)</span></label>
                    <input type="text" placeholder='Nhập tiêu để bài viết' {...register('title')} required={true} />
                </div>
                <div className={styles.item_Recruitment}>
                    <label>Người viết bài<span>(*)</span> </label>
                    <input type='text' placeholder='Nhập tên người viết bài'{...register('name')} required={true} />
                </div>
                <div className={styles.item_Recruitment}>
                    <label>Ảnh bài viết <span>(*)</span></label>
                    {
                        selectedFileName ?
                            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '0', cursor: 'pointer' }} onClick={() => onClick()}>
                                    <span>&#10005;</span>
                                </div>
                                <img src={urlFile} style={{ width: '100px', height: '250px', objectFit: 'contain' }} alt='img'/>
                                {selectedFileName}
                            </div> :
                            (< input type='file' {...register('fileName')} onChange={handleFileChange} required={true} />)
                    }
                </div>
                <div className={styles.item_Recruitment}>
                    <label>Nội dung viết bài<span>(*)</span></label>
                    <Editor onContentChange={setEditorContent} keyProp={refreshEditorKey} />
                </div>
                <button className={styles.btn_submit} type="submit">Gửi bài viết</button>
            </form>
            <ModalThankYou isOpen={openModal} onCancel={onCloseModal} />
        </>

    )
}
export default PostNews
import { useParams } from "react-router-dom";
import Slider from "../HomePage/slider"
import styles from '../../styles/DetailNews/index.module.css'
import LatestNews from "./newNews";
import CommentNews from "./commentNews";
import useDataDetail from "../../data/dataDetail"
import apiConfig from "../../constants/apiConfig";
const DetailNews = () => {
    const { newsId } = useParams();
    const newsInfo = useDataDetail(apiConfig.news.detailNews.path.replace(':id', newsId), apiConfig.news.detailNews.requestOption);
    const date = newsInfo?.dateCreated?.split("-")[2].split('T')[0];
    const month = newsInfo?.dateCreated?.split("-")[1];
    const year = newsInfo?.dateCreated?.split("-")[0];
    const dataComment = useDataDetail(apiConfig.news.getComment.path.replace(':id', newsId), apiConfig.news.getComment.requestOption)
    const getDate = () => {
        return (
            <div className={styles.newsDate}>
                <svg xmlns="http://www.w3.org/2000/svg" width="53" height="58" viewBox="0 0 53 58" fill="none">
                    <rect x="11" y="28" width="6" height="6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="11" y="41" width="6" height="6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="24" y="28" width="6" height="6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="24" y="41" width="6" height="6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="36" y="28" width="6" height="6" fill="#FFD550" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <rect x="36" y="41" width="6" height="6" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M52 20H1V9C1 7.34315 2.34315 6 4 6H49C50.6569 6 52 7.34315 52 9V20Z" fill="#FFD550" />
                    <path d="M50 5.66699H3.33333C2.04467 5.66699 1 6.71166 1 8.00033V54.667C1 55.9557 2.04467 57.0003 3.33333 57.0003H50C51.2887 57.0003 52.3333 55.9557 52.3333 54.667V8.00033C52.3333 6.71166 51.2887 5.66699 50 5.66699Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M40.668 1V10.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.668 1V10.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 19.667H52.3333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div>
                    <div className={styles.news_date}>
                        {date}
                    </div>
                    <div className={styles.news_month}>
                        {month}/{year}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={styles.div_detailNews}>
            <Slider
                isShowTitle={true}
                isSlider={false}
                imgUrl={'/assets/images/banner_introduce.jpg'}
                title={getDate()}
                // content={'Tiêu đề tin tức'}
                style={styles.date}
            />
            <div className={styles.divNews}>
                <div className={styles.containerNews}>
                    <div className={styles.newsName}>
                        {newsInfo.title}
                    </div>
                    <div className={styles.newsContent} dangerouslySetInnerHTML={{ __html: newsInfo.content }}>
                    </div>
                </div>
                <div className={styles.containerNewNews}>
                    <LatestNews />
                </div>
            </div>
            <div className={styles.divComment}>
                <CommentNews data={dataComment} idNews={newsId} />
            </div>
        </div>
    )
}
export default DetailNews
import styles from '../../styles/HomePage/customerComponent.module.css'
import useDataCustomer from "../../data/dataCustomer";
const CustomerContainer = () => {
    const dataCustomer = useDataCustomer();
    return (
        <div className={styles.div_customer}>
            <div className={styles.title}>
                Đối tác của chúng tôi
            </div>
            <div className={styles.container_logo_customer}>
                {dataCustomer.slice(0, 8).map((item) => {
                    return (
                        <img className={styles.item_logo} key={item.id} src={item.logo} alt="logoCustomer" />
                    )
                })}
            </div>
            <div className={styles.img_bottom_customer}>
                <img src="/assets/images/bottom_img_customer.jpg" style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="banner_customer" />
            </div>
        </div>
    )
}
export default CustomerContainer
import styles from '../../styles/Project/index.module.css'
import ProjectContainer from '../HomePage/project'
import Slider from '../HomePage/slider'
import ListProject from './listProject'
const ProjectPage = () => {
    return (
        <div className={styles.container_project}>
            <Slider
                isShowTitle={true}
                isSlider={false}
                imgUrl={'/assets/images/banner_project.jpg'}
                content={'Dự án của chúng tôi'}
            />
            <ProjectContainer isShowBtn={false} title={'Dự án nổi bật'} />
            <ListProject />
        </div>
    )
}

export default ProjectPage
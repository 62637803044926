import { Link } from 'react-router-dom'
import styles from '../../styles/DetailNews/newNews.module.css'
import useDataDetail from '../../data/dataDetail'
import apiConfig from '../../constants/apiConfig'
import { formatDateVN } from '../../hook/dateFormat'
const LatestNews = () => {
    const dataNews = useDataDetail(apiConfig.news.allNews.path, apiConfig.news.allNews.requestOption);

    return (
        <div className={styles.divLatestNews}>
            <div className={styles.title}>
                Tin tức mới
            </div>
            <div className={styles.containerLatestNews}>
                {
                    dataNews.slice(0, 4).map(
                        (item) => (
                            <div className={styles.itemNews} key={item.id}>
                                <div>
                                    <img src={item.thumbnailImage} style={{ width: '200px', height: '100%', objectFit: 'contain' }} alt='imgNews' />
                                </div>
                                <div>
                                    <div className={styles.item_title}>
                                        <Link to={`/detailNews/${item.id}`} reloadDocument>{item.title}</Link>
                                    </div>
                                    <div className={styles.item_date}>
                                        {formatDateVN(item.dateCreated)}
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
            </div>
            <div className={styles.div_seeMore}>
                <div className={styles.btn_seeMore}>
                    <Link to={'/news'} reloadDocument>Xem thêm</Link>
                </div>
            </div>
        </div>
    )
}
export default LatestNews
import { Modal } from "antd"
import styles from '../../styles/News/modalThankyou.module.css'
const ModalThankYou = ({ isOpen = false, onCancel }) => {
    return (
        <>
            <Modal open={isOpen} onCancel={onCancel} footer={false} width={'100%'} style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles.formModal}>
                    <img src="/assets/images/thankyou.png" alt="img"/>
                    <div className={styles.content}>
                        Cám ơn bạn đã gửi bài,
                        bài viết của bạn đang được xử lý
                    </div>
                    {/* <div className={styles.btnBack} onClick={onCancel}>
                        Xem lại bài viết
                    </div> */}
                    <div className={styles.btnClose} onClick={onCancel}>
                        Đóng
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default ModalThankYou
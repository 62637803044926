import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, } from 'react-router-dom';
import ErrorPage from './Component/ErrorPage';
import Navbar from './Component/NavBar';
import dataMenu from './data/dataMenu'
import Footer from './Component/Footer';
import DetailProject from './Component/DetailProject';
import DetailNews from './Component/DetailNews';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          {dataMenu.map((item) => (
            <Route key={item.path} path={item.path} element={item.component} />
          ))}
          <Route path="/detailProject/:projectId" element={<DetailProject />} />
          <Route path="/detailNews/:newsId" element={<DetailNews />} />
          <Route path="/404" element={<ErrorPage />} />
          <Route path="/*" element={<Navigate to="/404" />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

export const ApiUrl = process.env.REACT_APP_API;
export const fieldTypes = {
    TEXT: 'TEXT',
    NUMBER: 'NUMBER',
    SELECT: 'SELECT',
    AUTOCOMPLETE: 'AUTOCOMPLETE',
    DATE: 'DATE',
    UPLOAD: 'UPLOAD',
    RADIO: 'RADIO',
    BOOLEAN: 'BOOLEAN',
    CHECKBOX: 'CHECKBOX'
}
export const storageKeys = {
    ACCESS_TOKEN: process.env.REACT_APP_ACCESS_TOKEN_KEY,
    REFRESH_TOKEN: process.env.REACT_APP_REFRESH_TOKEN_KEY,
}
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'
import styles from '../..//App.module.css'
import dataMenu from '../../data/dataMenu'
import useDataCompany from '../../data/dataCompany';
const Navbar = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [viewportWidth, setViewportWidth] = useState()
    const location = useLocation();
    const navigate = useNavigate();
    const handleToggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    const removeActive = (path) => {
        navigate(path);
        window.location.reload(true);
        setIsNavOpen(false)
    }
    const dataContact = useDataCompany()
    useEffect(() => {
        const updateViewportWidth = () => {
            const width = window.innerWidth;
            setViewportWidth(width);
        };
        updateViewportWidth();
        window.addEventListener('resize', updateViewportWidth);
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, [viewportWidth]);
    return (
        <>
            <div className={styles.header_navBar}>
                <Link to="/" className={styles.logo} reloadDocument>
                    <img src='/assets/images/logo.png' alt='logo' style={{ width: viewportWidth < 900 ? '80px' : '100%', height: viewportWidth < 900 ? '80px' : '100%', objectFit: 'contain' }} />
                </Link>
                <div className={styles.container_contact} style={{ display: 'flex', gap: '19px' }}>
                    <div key={dataContact.id} className={styles.container_contact}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <img src={'/assets/images/clock.png'} alt='contact_img' />
                            <div className={styles.content_contact}>
                                <div>
                                    Mon- Sat 08:00- 17:00
                                </div>
                                <div>
                                    Sunday Closed
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <img src={'/assets/images/mail.png'} alt='contact_img' />
                            <div className={styles.content_contact}>
                                <div>
                                    Email
                                </div>
                                <div>
                                    {dataContact.email}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
                            <img src={'/assets/images/phone.png'} alt='contact_img' />
                            <div className={styles.content_contact}>
                                <div>
                                    Liên hệ
                                </div>
                                <div>
                                    {dataContact.phone}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nav className={`${styles.navbar} `}>
                <div className={styles.menu_icon} onClick={handleToggleNav}>
                    <FaBars color="#FFF" />
                </div>
                <div className={` ${styles.container_navbar} ${isNavOpen ? `${styles.open}` : `${styles.notOpen}`}`}>
                    <ul >
                        <div className={styles.item_navBar}>
                            {
                                dataMenu.map(
                                    (item) => (
                                        <li onClick={() => removeActive(item.path)} key={item.id}>
                                            <Link to={item.path} className={location.pathname === item.path ? `${styles.active}` : ''}>
                                                {item.title}
                                            </Link>
                                        </li>
                                    )
                                )
                            }
                        </div>
                        <div className={styles.social}>
                            <div className={styles.item_social}>
                                <img src='/assets/images/Instagram.png' alt='social' />
                            </div>
                            <div className={styles.item_social}>
                                <img src='/assets/images/Facebook.png' alt='social' />
                            </div>
                            <div className={styles.item_social}>
                                <img src='/assets/images/Twitter.png' alt='social' />
                            </div>
                            <div className={styles.item_social}>
                                <img src='/assets/images/Linkdin.png' alt='social' />
                            </div>
                        </div>
                    </ul>
                </div>
            </nav >
        </>
    );
}

export default Navbar
import styles from '../../styles/Recruitment/index.module.css'
import Slider from '../HomePage/slider'
import InfoCompany from '../Introduce/infoCompany'
import ApplicationInformation from './applicationInformation'
const RecruitmentPage = () => {
    return (
        <div className={styles.div_recruitment}>
            <Slider
                isSlider={false}
                imgUrl={'/assets/images/banner_introduce.jpg'}
                isShowTitle={true}
            // title={''}
            // content={'Chúng tôi là'}
            />
            <InfoCompany />
            <ApplicationInformation />
        </div>
    )
}

export default RecruitmentPage
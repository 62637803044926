import styles from "../../styles/HomePage/valueComponent.module.css"
import useDataValue from "../../data/dataValue";
const ValueContainer = () => {
    const dataValue = useDataValue(6);
    return (
        <div className={styles.div_value}>
            <div className={styles.container_left}>
                <div className={styles.img_value}>
                    <img src="/assets/images/banner_value.jpg" style={{ width: '100%', height: '100%' }} alt="banner_value" />
                </div>
            </div>
            <div className={styles.container_right}>
                <div className={styles.content_value}>
                    <div className={styles.title}>
                        Giá trị cốt lõi
                    </div>
                    <div className={styles.container_value}>
                        {dataValue.map((item) => {
                            return (
                                <div key={item.id} className={styles.item_value}>
                                    <img src={item.image} className={styles.icon_value} alt="icon-value" />
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ValueContainer
import Slider from "./slider"
import styles from "../../styles/HomePage/HomePage.module.css"
import ProjectContainer from "./project";
import NewsContainer from "./newsComponent";
import ValueContainer from "./valueComponent";
import CustomerContainer from "./customerComponent";
const HomePage = () => {
    return (
        <div className={styles.container}>
            <Slider
                isSlider={true}
            />
            <ProjectContainer title={'Dự án tiêu biểu'} />
            <NewsContainer />
            <ValueContainer />
            <CustomerContainer />
        </div >
    )
}

export default HomePage
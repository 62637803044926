import Tabs from '../Tabs'
import CoreValue from './coreValue';
import CustomerList from './custommerList';
import FieldOfActivity from './fieldOfActivity';
import InfoCompany from './infoCompany'
import StaffList from './staffList';
import Slider from '../HomePage/slider'
const IntroducePage = () => {
    const tabData = [
        { id: 1, label: 'Giá trị cốt lõi', content: <CoreValue /> },
        { id: 2, label: 'Nhân sự tại Bincon', content: <StaffList /> },
        { id: 3, label: 'Lĩnh vực hoạt động', content: <FieldOfActivity /> },
        { id: 4, label: 'Khách hàng', content: <CustomerList /> },
    ];
    return (
        <>
            <Slider
                isSlider={false}
                imgUrl={'/assets/images/banner_introduce.jpg'}
                // isShowTitle={true}
                // title={'Về chúng tôi'}
                // content={'Chúng tôi là'}
            />
            <InfoCompany />
            <Tabs tabs={tabData} />
        </>
    )
}
export default IntroducePage
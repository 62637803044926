import React, { useEffect, useState } from 'react';
import styles from '../../styles/News/TabNews.module.css'
import useDataNewsWithCategory from '../../data/dataNew';
import stylesPagination from '../../styles/PaginationPage/customerPagination.module.css'
import { Link } from 'react-router-dom';
import PostNews from '../News/addNews';
import { formatDateUS } from '../../hook/dateFormat';
const TabNews = ({ categoryNewsData }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [idTab, setIdTab] = useState(1);
    const newsData = useDataNewsWithCategory(idTab);

    useEffect(() => {
        // Chọn tab đầu tiên khi component được mount
        handleTabClick(0, categoryNewsData[0]);
    }, [categoryNewsData]);
    const handleTabClick = (index, category) => {
        setActiveTab(index);
        setIdTab(category?.id);
        setCurrentPage(1)
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [viewportWidth, setViewportWidth] = useState();
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentNews = newsData.slice(startIndex, endIndex);

    const totalPages = Math.ceil(newsData.length / itemsPerPage);
    const generatePageArray = () => {
        const pagesToShow = 2; // Số trang muốn hiển thị ở đầu và cuối
        const pageArray = [];

        for (let i = 1; i <= totalPages; i++) {
            if (i <= pagesToShow || i > totalPages - pagesToShow || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageArray.push(i);
            } else if (pageArray[pageArray.length - 1] !== '......') {
                pageArray.push('......');
            }
        }

        return pageArray;
    };
    const pageArray = generatePageArray();
    const handlePageChange = (newPage) => {
        if (newPage === '......') {
            return;
        }
        setCurrentPage(newPage);
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePageChange(currentPage + 1);
        }
    };
    useEffect(() => {
        const updateViewportWidth = () => {
            const width = window.innerWidth;
            setViewportWidth(width);
        };
        updateViewportWidth();
        window.addEventListener('resize', updateViewportWidth);
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, [viewportWidth]);
    return (
        <div>
            <div className={styles.tab_list}>
                <div className={styles.container_tabs}>
                    {categoryNewsData?.map((category, index) => (
                        <div
                            key={category.id}
                            className={`${styles.tab} ${index === activeTab ? `${styles.active}` : ''}`}
                            onClick={() => handleTabClick(index, category)}
                        >
                            {category.name}
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ display: viewportWidth > 900 ? 'flex' : 'block' }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', flex: 2 }} >
                    <div className={styles.tab_content}>
                        {currentNews?.map((news) => (
                            <div key={news.id} className={styles.item}>
                                <div>
                                    <img src={news.thumbnailImage}
                                        style={{
                                            width: '100%',
                                            height: viewportWidth < 900 ? '100%' : 900 < viewportWidth < 1240 ? '200px' : 1241 < viewportWidth < 1601 ? '200px' : '200px',
                                            objectFit: 'cover'
                                        }} alt='imgThumbnail' />
                                </div>
                                <div className={styles.newsDate}>
                                    {formatDateUS(news.dateCreated)}
                                </div>
                                <div className={styles.newsTitle}>
                                    {news.title}
                                </div>
                                {/* <div className={styles.newsContent} dangerouslySetInnerHTML={{ __html: truncateText(news.content, 100) }}>

                                </div> */}
                                <div className={styles.seeMore}>
                                    <Link to={`/detailNews/${news.id}`} reloadDocument>Xem chi tiết &#8594;</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ flex: '1', display: 'flex', flexDirection: 'column', marginTop: '50px', height: 'max-content' }}>
                    <PostNews categoryNewsId={idTab} />
                </div>
            </div>
            <div className={stylesPagination.containerPagination} style={{ marginBottom: '10px' }}>
                <span onClick={handlePreviousPage} className={`${stylesPagination.btn_previous} ${currentPage === 1 ? stylesPagination.disable : ''}`}>
                    &lt; Previous
                </span>
                {pageArray.map((page, index) => (
                    <span
                        key={index}
                        onClick={() => handlePageChange(page)}
                        className={`${stylesPagination.itemPagination} ${currentPage === page ? stylesPagination.activePage : ''} ${page === '......' ? stylesPagination.dot : ''}`}
                    >
                        {page}
                    </span>
                ))}
                <span onClick={handleNextPage} className={`${stylesPagination.btn_next} ${currentPage === totalPages ? stylesPagination.disable : ''}`}>
                    Next &gt;
                </span>
            </div>

        </div>
    );
};


export default TabNews;

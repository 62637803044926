import styles from '../../styles/Recruitment/applicationInformation.module.css'
import FormRecruitment from './formRecruitment'
const ApplicationInformation = () => {
    return (
        <div className={styles.div_applicationInfo}>
            <div className={styles.title}>
                Thông tin ứng tuyển
            </div>
            <div className={styles.container_info}>
                <FormRecruitment />
                <div style={{ flex: 1 }}>
                    <img src='/assets/images/bgr_cv.jpg' style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='imgCv' />
                </div>
            </div>
        </div>
    )
}
export default ApplicationInformation
import { useState } from 'react'
import { Link } from 'react-router-dom'
import dataMenu from '../../data/dataMenu'
import styles from '../../styles/Footer/Footer.module.css'
import useDataCompany from '../../data/dataCompany'
const Footer = () => {
    const dataContact = useDataCompany()
    const [email, setEmail] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmail('');

    };
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const data = useDataCompany();
    return (
        <div className={styles.div_footer}>
            <div className={styles.container_footer}>
                <div className={styles.first_column}>
                    <img src='/assets/images/logo.png' className={styles.logo} alt='logo' />
                    <div className={styles.div}>
                        <div className={styles.info}>
                            <img src={'/assets/images/mail.png'} alt='contact_img' />
                            <div className={styles.content_contact}>
                                <div>
                                    Email
                                </div>
                                <div>
                                    {dataContact.email}
                                </div>
                            </div>
                        </div>
                        <div className={styles.info}>
                            <img src={'/assets/images/phone.png'} alt='contact_img' />
                            <div className={styles.content_contact}>
                                <div>
                                    Liên hệ
                                </div>
                                <div>
                                    {dataContact.phone}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.content_column}>
                    <div className={styles.second_column}>
                        <div className={styles.title}>
                            Sitemap
                        </div>
                        <div className={styles.content}>
                            {dataMenu.slice(1, 6).map((item) => {
                                return (
                                    <Link to={item.path} key={item.id} reloadDocument>
                                        {item.title}
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.third_column}>
                        <div className={styles.title}>
                            Thông tin
                        </div>
                        <div className={styles.content}>
                            {data.fullName}
                            <span> Địa chỉ: {data.address}</span>
                            <span>MST: {data.taxCode}</span>
                            <span>Web: {data.website}</span>
                        </div>
                    </div>
                    <div className={styles.four_column}>
                        <div className={styles.title}>
                            Liên hệ
                        </div>
                        <form onSubmit={handleSubmit} className={styles.form_contact}>
                            <label>
                                <input className={styles.input_mail} type="text" placeholder="Email*" value={email} onChange={(e) => handleChangeEmail(e)} />
                            </label>
                            <div className={styles.container_btn}>
                                <div onClick={handleSubmit} className={styles.btn_submit} >
                                    Gửi
                                </div>
                                <div className={styles.social}>
                                    <div className={styles.item_social}>
                                        <img src='/assets/images/Instagram.png' alt='social' />
                                    </div>
                                    <div className={styles.item_social}>
                                        <img src='/assets/images/Facebook.png' alt='social' />
                                    </div>
                                    <div className={styles.item_social}>
                                        <img src='/assets/images/Twitter.png' alt='social' />
                                    </div>
                                    <div className={styles.item_social}>
                                        <img src='/assets/images/Linkdin.png' alt='social' />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={styles.line}>
            </div>
            <div className={styles.copyright}>
                Copyright © TransitFlow | Designed by VictorFlow - Powered by Webflow.
            </div>
        </div>
    )
}
export default Footer
import { useState } from "react";
import styles from '../../styles/Project/listProject.module.css'
import stylesPagination from '../../styles/PaginationPage/customerPagination.module.css'
import { Link } from "react-router-dom";
import useDataProject from '../../data/dataProject'
import apiConfig from "../../constants/apiConfig";
const ListProject = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const dataProject = useDataProject(apiConfig.project.listProject.path, apiConfig.project.listProject.requestOption);
    const itemsPerPage = 5;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentProject = dataProject.slice(startIndex, endIndex);


    const totalPages = Math.ceil(dataProject.length / itemsPerPage);
    const generatePageArray = () => {
        const pagesToShow = 2;
        const pageArray = [];

        for (let i = 1; i <= totalPages; i++) {
            if (i <= pagesToShow || i > totalPages - pagesToShow || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageArray.push(i);
            } else if (pageArray[pageArray.length - 1] !== '......') {
                pageArray.push('......');
            }
        }

        return pageArray;
    };
    const truncateContent = (text, maxLength) => {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substr(0, maxLength) + '...';
    };
    const pageArray = generatePageArray();
    const handlePageChange = (newPage) => {
        if (newPage === '......') {
            return;
        }
        setCurrentPage(newPage);
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePageChange(currentPage + 1);
        }
    };
    const handleBtnHover = (itemId) => {
        setHoveredItemId(itemId);
    };

    const handleBtnLeave = () => {
        setHoveredItemId(null);
    };
    return (
        <div className={styles.container_listProject}>
            {currentProject.map((item) => (
                <div key={item.id} className={styles.item_Project}>
                    <div className={styles.imgCustomer}>
                        <img src={item.avatarImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="imgCustomer" />
                    </div>
                    <div className={styles.item_content}>
                        <div className={`${styles.name} ${hoveredItemId === item.id ? styles.hovered : ''}`}>
                            {item.name}
                        </div>
                        <div className={styles.content} dangerouslySetInnerHTML={{ __html: truncateContent(item.content, 200) }}>
                        </div>
                        <div className={styles.btn} onMouseEnter={() => handleBtnHover(item.id)} onMouseLeave={handleBtnLeave}>
                            <Link to={`/detailProject/:projectId`.replace(':projectId', item.id)} reloadDocument> Xem chi tiết</Link>
                        </div>
                    </div>
                </div>
            ))}
            <div className={styles.line}>

            </div>
            <div className={stylesPagination.containerPagination}>
                <span onClick={handlePreviousPage} className={`${stylesPagination.btn_previous} ${currentPage === 1 ? stylesPagination.disable : ''}`}>
                    &lt; Previous
                </span>
                {pageArray.map((page, index) => (
                    <span
                        key={index}
                        onClick={() => handlePageChange(page)}
                        className={`${stylesPagination.itemPagination} ${currentPage === page ? stylesPagination.activePage : ''} ${page === '......' ? stylesPagination.dot : ''}`}
                    >
                        {page}
                    </span>
                ))}
                <span onClick={handleNextPage} className={`${stylesPagination.btn_next} ${currentPage === totalPages ? stylesPagination.disable : ''}`}>
                    Next &gt;
                </span>
            </div>
        </div>
    )
}
export default ListProject
// dateFormatter.js
import { format, getMonth, parseISO, } from 'date-fns';
import { vi } from 'date-fns/locale'; // Import locale for Vietnamese
export const formatDateVN = (inputDate) => {
    const date = new Date(inputDate);
    return format(date, 'dd MMMM', { locale: vi });
};
export const formatDateUS = (inputDate) => {
    const date = new Date(inputDate);
    return format(date, 'dd/MM/yyyy');
};
export const formatDateDayAndMonth = (dateString) => {
    const dateObject = parseISO(dateString);
    const day = format(dateObject, 'dd', { locale: vi });
    const month = format(dateObject, 'MMMM', { locale: vi });
    return { day, month };
};
export const formatDateDayAndMonthAndYear = (dateString) => {
    const dateObject = parseISO(dateString);
    const day = format(dateObject, 'dd');
    const month = getMonth(dateObject) + 1;
    const year = format(dateObject, 'yyyy')
    return { day, month, year };
};
import { useEffect, useState } from 'react';
import styles from '../../styles/DetailNews/commentNews.module.css'
import CommentList from './commentList';
import ModalInfoComment from './modalInfoComment'
const CommentNews = ({ data, idNews }) => {
    const [sortBy, setSortBy] = useState('latest');
    const [listComment, setListComment] = useState([]);
    const [comment, setComment] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [dataComment, setDataComment] = useState('')
    useEffect(() => {
        setListComment(data ? data : [])
    }, [data])
    const handleSortChange = (event) => {
        setSortBy(event.target.value);
    };
    const sortedComments = [...listComment]?.sort((a, b) => {
        return sortBy === 'latest' ? b.dateCreated - a.dateCreated : a.dateCreated - b.dateCreated;
    });
    const totalComment = data?.length;
    const handleChangeComment = (e) => {
        setComment(e.target.value)
    }
    const onCancelModal = () => {
        setIsOpenModal(false)
    }
    const onComment = (dataComment) => {
        setIsOpenModal(true)
        setDataComment(dataComment)
        setComment('')
    }
    return (
        <>
            <div className={styles.containerComment}>
                <div className={styles.title}>
                    <span>Bình luận</span>
                </div>
                <div className={styles.myComment}>
                    <div style={{ width: '72px', height: '72px', flex: '1' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="73" height="72" viewBox="0 0 73 72" fill="none" style={{ width: '72px', height: '72px' }}>
                            <circle cx="36.4043" cy="34.9043" r="36.9043" fill="url(#paint0_linear_141_4226)" />
                            <circle cx="36.5" cy="24" r="9" stroke="black" strokeWidth="2" />
                            <path d="M49.5 50.6136C49.5 51.715 49.4047 52.4898 49.2535 53.0227C49.1034 53.552 48.9206 53.7666 48.7979 53.859C48.6845 53.9445 48.5052 54.0145 48.1581 53.9975C47.7949 53.9796 47.3266 53.8693 46.7243 53.6624C46.1276 53.4575 45.4652 53.1819 44.7133 52.8675L44.6616 52.8459C43.9341 52.5416 43.1354 52.2076 42.2931 51.8994C40.57 51.2688 38.6018 50.7212 36.5 50.7212C34.3982 50.7212 32.43 51.2688 30.7069 51.8994C29.8646 52.2076 29.0659 52.5416 28.3384 52.8459L28.2867 52.8675C27.5348 53.1819 26.8724 53.4575 26.2757 53.6624C25.6734 53.8693 25.2051 53.9796 24.8419 53.9975C24.4948 54.0145 24.3155 53.9445 24.2021 53.859C24.0794 53.7666 23.8966 53.552 23.7465 53.0227C23.5953 52.4898 23.5 51.715 23.5 50.6136C23.5 41.8232 29.4739 35 36.5 35C43.5261 35 49.5 41.8232 49.5 50.6136Z" stroke="black" strokeWidth="2" />
                            <defs>
                                <linearGradient id="paint0_linear_141_4226" x1="-2.59251" y1="13.9918" x2="90.2593" y2="20.5857" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FFB629" />
                                    <stop offset="0.507189" stopColor="#FFDA56" />
                                    <stop offset="1" stopColor="#FFD7A6" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className={styles.inputComment}>
                        <input type="text" placeholder="Vui lòng cho chúng tôi biết bạn là ai " value={comment} onChange={(e) => handleChangeComment(e)} />
                    </div>
                    <div className={styles.btn_comment} onClick={() => { onComment(comment) }}>
                        Bình luận
                    </div>
                </div>
                <div className={styles.totalComment}>
                    <div className={styles.total}>
                        {totalComment ? totalComment : '0'} bình luận
                    </div>
                    <div className={styles.sortBy}>
                        <select value={sortBy} onChange={handleSortChange}>
                            <option value="latest">Mới Nhất</option>
                            <option value="oldest">Cũ Nhất</option>
                        </select>
                    </div>
                </div>
                <CommentList comments={sortedComments} />
            </div>
            <ModalInfoComment isOpen={isOpenModal} onCancel={onCancelModal} dataComment={dataComment} idNews={idNews} />
        </>
    )
}
export default CommentNews
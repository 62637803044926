import { Link } from 'react-router-dom';
import { useState } from 'react';
import styles from '../../styles/HomePage/newComponent.module.css'
import { formatDateUS } from "../../hook/dateFormat";
import useDataDetail from '../../data/dataDetail'
import apiConfig from '../../constants/apiConfig';
const NewsContainer = () => {
    const [hoveredItemId, setHoveredItemId] = useState(null);
    const handleBtnHover = (itemId) => {
        setHoveredItemId(itemId);
    };
    const dataNews = useDataDetail(apiConfig.news.featuredNews.path, apiConfig.news.featuredNews.requestOption)
    const handleBtnLeave = () => {
        setHoveredItemId(null);
    };
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }
    return (
        <div className={styles.div_news}>
            <div className={styles.news}>
                <div className={styles.title}>
                    Tin tức nổi bật
                </div>
                <div className={styles.container_news}>
                    {dataNews.slice(0, 4).map((item) => {
                        return (
                            <div key={item.id} className={styles.item}>
                                <div className={styles.item_img}>
                                    <img src={item.thumbnailImage} alt="img_news" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                    <div className={styles.overlay} onMouseEnter={() => handleBtnHover(item.id)} onMouseLeave={handleBtnLeave}>
                                        <Link to={`/detailNews/${item.id}`} reloadDocument>
                                            Xem chi tiết
                                        </Link>
                                    </div>
                                </div>
                                <div className={styles.item_date}>
                                    <img src='/assets/images/date_icon.png' alt="img_news" style={{ width: '40px', height: '56px', objectFit: 'contain' }} />
                                    <div>
                                        <div className={styles.news_date}>
                                            {formatDateUS(item?.dateCreated).split('/')[0]}
                                        </div>
                                        <div className={styles.news_month}>
                                            tháng {formatDateUS(item?.dateCreated).split('/')[1]}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.item_content}>
                                    <div className={`${styles.nameNews} ${hoveredItemId === item.id ? styles.hovered : ''}`}>
                                        {item.title}
                                    </div>
                                    <div className={styles.contentNews} dangerouslySetInnerHTML={{ __html: truncateText(item.content, 450) }}>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={styles.btn_seeMore}>
                    <Link to={"/news"} reloadDocument><div>Xem thêm</div></Link>
                </div>
            </div>
        </div>
    )
}
export default NewsContainer
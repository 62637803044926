import { useForm } from 'react-hook-form';
import styles from '../../styles/Recruitment/formRecruitment.module.css'
import apiConfig from '../../constants/apiConfig';
import { useState } from 'react';
const FormRecruitment = () => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const [selectedFileName, setSelectedFileName] = useState('');
    const [urlFile, setUrlFile] = useState('')
    const onSubmit = (data) => {
        // Xử lý dữ liệu khi người dùng gửi form
        const requestBody = {
            fullName: data.name,
            jobTitle: data.jobTitle,
            email: data.email,
            phoneNumber: data.phone,
            content: data.content,
            cvLink: data.fileName
        };
        const requestOption = {
            ...apiConfig.candidate.postCV.requestOption,
            body: JSON.stringify(requestBody)
        }
        fetch(apiConfig.candidate.postCV.path, requestOption)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                reset();
                setSelectedFileName('');
                setUrlFile('')
            })
            .catch(error => {
                console.error('Comment submission error:', error);
            });
    };
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        setSelectedFileName(file ? file.name : '');
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(apiConfig.media.image.path, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            const fileLink = data.data;

            setValue('fileName', fileLink);
            setUrlFile(fileLink);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    const onClick = () => {
        setSelectedFileName('');
        setUrlFile('')
        setValue('fileName', '');
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form_Recruitment}>
            <div className={styles.item_Recruitment}>
                <label>Họ và tên </label>
                <input type="text" placeholder='Nhập họ và tên' {...register('name')} />
            </div>
            <div className={styles.item_Recruitment}>
                <label>Email address </label>
                <input type='email' placeholder='Nhập email liên hệ'{...register('email')} />
            </div>

            <div className={styles.item_Recruitment}>
                <label>Số điện thoại </label>
                <input type="text" placeholder='Nhập số điện thoại liên hệ' {...register('phone')} />
            </div>
            <div className={styles.item_Recruitment}>
                <label>Vị trí ứng tuyển </label>
                <input type="text" placeholder='Nhập vị trí ứng tuyển' {...register('jobTitle')} />
            </div>
            <div className={styles.item_Recruitment}>
                <label>nội dung </label>
                <input type="text" placeholder='Nhập nội dụng liên hệ'{...register('content')} style={{ height: '140px' }} />
            </div>
            <div className={styles.item_Recruitment}>
                {
                    selectedFileName ?
                        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '0', cursor: 'pointer' }} onClick={() => onClick()}>
                                <span>&#10005;</span>
                            </div>
                            <img src={urlFile} style={{ width: '100px', height: '250px', objectFit: 'contain' }} alt='img'/>
                            {selectedFileName}
                        </div> :
                        (< input type='file' {...register('fileName')} onChange={handleFileChange} />)
                }
            </div>
            <button className={styles.btn_submit} type="submit">Gửi hồ sơ</button>
        </form>
    )
}
export default FormRecruitment
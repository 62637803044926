import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import 'react-quill/dist/quill.snow.css';
import apiConfig from "../../constants/apiConfig";

// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

class Editor extends Component {

    constructor(props) {
        super(props);
        this.state = { editorHtml: "" };
        this.handleChange = this.handleChange.bind(this);
        this.textInput = React.createRef();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.keyProp !== this.props.keyProp) {
            // Đặt lại giá trị nội dung của Editor khi keyProp thay đổi
            this.setState({ editorHtml: "" });
        }
    }
    handleChange(html) {
        this.setState({ editorHtml: html });
        this.props.onContentChange(html);
    }

    handleSubmit() {
        const editor = this.reactQuillRef.getEditor();
        this.setState({
            editorHtml: editor
        });
    }
    modules = {
        // #3 Add "image" to the toolbar
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" }
            ],
            ["link", "image"],
            ["clean"]
        ],
        // # 4 Add module and upload function
        imageUploader: {
            upload: (file) => {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("file", file);

                    fetch(
                        apiConfig.media.image.path,
                        {
                            method: "POST",
                            body: formData
                        }
                    )
                        .then((response) => response.json())
                        .then((result) => {
                            resolve(result.data);
                        })
                        .catch((error) => {
                            reject("Upload failed");
                            console.error("Error:", error);
                        });
                });
            }
        }
    };

    formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "imageBlot" // #5 Optinal if using custom formats
    ];

    render() {
        return (
            <>
                <ReactQuill
                    onChange={this.handleChange}
                    theme="snow"
                    style={{
                        minHeight: "25vh"
                    }}
                    modules={this.modules}
                    formats={this.formats}
                    value={this.state.editorHtml}
                />
            </>
        );
    }
}

export default Editor;

import styles from '../../styles/Introduce/coreValue.module.css'
import useDataValue from '../../data/dataValue';
import useDataVision from '../../data/dataVision';
const CoreValue = () => {
    const dataCoreValue = useDataValue();
    const dataVision = useDataVision();
    const getColor = (index) => {
        return index % 4 >= 2 ? { backgroundColor: '#091242' } : null;
    };
    return (
        <div className={styles.container_coreValue}>
            <div className={styles.message_and_vision}>
                <div className={styles.container_message_and_vision}>
                    {dataVision.map((item) => (
                        <div key={item.id} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <div className={styles.title}>
                                Thông điệp & tầm nhìn
                            </div>
                            <div className={styles.info}>
                                <div className={styles.container_User}>
                                    <div className={styles.img_user}>
                                        <img src={item.avatarImage} alt='User' style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '50%' }} />
                                    </div>
                                    <div className={styles.info_user}>
                                        <div className={styles.name_user}>
                                            {item.author}
                                        </div>
                                        <div className={styles.position}>
                                            {item.role}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className={styles.icon}>
                                    <img src='/assets/images/use_icon.png' alt='icon' style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                </div> */}
                            </div>
                            <div className={styles.content}>
                                {item.content}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={styles.core_value}>
                <div className={styles.title} style={{ paddingBottom: '40px' }}>
                    Giá trị cốt lõi
                </div>
                {dataCoreValue.map((item, index) => (
                    <div key={item.id} className={index === 0 ? styles.first_item : styles.container_order_item}
                        style={getColor(index)}
                    >
                        <div className={index !== 0 ? styles.oder_item : styles.first_item_container}>
                            <div className={styles.container_oder_info}>
                                <div className={styles.img_oder}>
                                    <img src={item.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt='img_core' />
                                </div>
                                <div className={styles.order_info}>
                                    <div className={index === 0 ? styles.first_item_name : styles.order_name}
                                        style={index >= 2 ? { color: index % 4 >= 2 ? '#FFF' : '#000' } : null}
                                    >
                                        {item.name}
                                    </div>
                                </div>
                            </div>
                            <div className={index === 0 ? styles.first_item_content : styles.order_content}
                                style={index >= 2 ? { color: index % 4 >= 2 ? '#FFF' : '#000' } : null}
                            >
                                {item.description}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default CoreValue
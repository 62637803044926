import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "antd"
import styles from '../../styles/DetailNews/modalInfoComment.module.css'
import apiConfig from "../../constants/apiConfig";
const ModalInfoComment = ({ isOpen = false, onCancel, dataComment, idNews }) => {
    const [captCha, setCaptCha] = useState('')
    const { register, handleSubmit, reset } = useForm();
    const [errorCode, setErrorCode] = useState('');
    const generateRandomString = () => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let randomString = "";

        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters.charAt(randomIndex);
        }
        setCaptCha(randomString);
        return randomString;
    }

    const handleCommentSubmit = (data) => {
        if (data.code === captCha) {
            const newCode = generateRandomString();
            setCaptCha(newCode);
            reset();
            const requestBody = {
                content: dataComment,
                newsId: idNews,
                name: data.name,
                email: data.email
            };
            const requestOption = {
                ...apiConfig.news.postComment.requestOption,
                body: JSON.stringify(requestBody)
            }
            fetch(apiConfig.news.postComment.path, requestOption)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Comment submitted successfully:', data);
                    onCancel();
                })
                .catch(error => {
                    console.error('Comment submission error:', error);
                });
        }
        else {
            const newCode = generateRandomString();
            setCaptCha(newCode);
            setErrorCode('Sai mã xác nhận')
        }
    };
    useEffect(() => {
        generateRandomString()
    }, [isOpen])
    return (
        <>
            <Modal title="Thông  tin bình luận" open={isOpen} onCancel={onCancel} footer={false}>
                <form onSubmit={handleSubmit(handleCommentSubmit)} className={styles.form_PostComment}>
                    <div className={styles.item_post}>
                        <label>Tên của bạn (<span>*</span>)</label>
                        <input type="text" placeholder='Nhập tên của bạn' {...register('name', { required: true })} />
                    </div>
                    <div className={styles.item_post}>
                        <label>Email (<span>*</span>)</label>
                        <input type="email" placeholder='Nhập email liên hệ'{...register('email', { required: true })} />
                    </div>

                    <div className={styles.item_post}>
                        <label>Mã xác nhận  (<span>*</span>)</label>
                        <div className={styles.captcha}>
                            <input {...register('code', { required: true })} onChange={() => { setErrorCode('') }} />
                            <div className={styles.code}>
                                <span>{captCha}</span>
                            </div>
                        </div>
                        <span className={styles.error}>{errorCode}</span>
                    </div>

                    <button className={styles.btn_submit} type="submit">Gửi bình luận</button>
                    <button className={styles.btn_cancel} onClick={() => onCancel()}>Đóng</button>
                </form>
            </Modal>
        </>
    )
}
export default ModalInfoComment
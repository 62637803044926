import HomePage from '../Component/HomePage';
import IntroducePage from '../Component/Introduce';
import NewsPage from '../Component/News';
import ProjectPage from '../Component/Project';
import RecruitmentPage from '../Component/Recruitment';
import DocumentPage from '../Component/DocumentPage';

const dataMenu = [
    {
        id: 1,
        path: '/',
        component: <HomePage />,
        title: 'Trang chủ'
    },
    {
        id: 2,
        path: '/introduce',
        component: <IntroducePage />,
        title: 'Giới thiệu'
    },

    {
        id: 3,
        path: '/project',
        component: <ProjectPage />,
        title: 'Dự án'
    },
    {
        id: 4,
        path: '/news',
        component: <NewsPage />,
        title: 'Tin tức'
    },
    {
        id: 5,
        path: '/document',
        component: <DocumentPage />,
        title: 'Tài liệu'
    },
    {
        id: 6,
        path: '/recruitment',
        component: < RecruitmentPage />,
        title: 'Tuyển dụng'
    },

]
export default dataMenu
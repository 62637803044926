import styles from '../../styles/DetailProject/carousel.module.css';
import React, { useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const CarouselImg = ({ dataImg }) => {
    const autoplay = true
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: dataImg?.length >= 3 ? 3 : 1,
        slidesToScroll: 1,
        autoplay,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };
    const data = dataImg?.map((item) => (
        {
            imgUrl: item.imageUrl
        }
    ))
    useEffect(() => {
        const interval = setInterval(() => {
            if (autoplay) {
                sliderRef.current.slickNext();
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [autoplay]);

    const sliderRef = React.useRef(null);
    return (
        <div className={styles.container_demo}>
            <Slider ref={sliderRef} {...settings} className={styles.demo}>
                {
                    data?.map((item, index) => {
                        return (
                            <div key={index} className={styles.container} >
                                <div className={styles.container_staff}>
                                    <img src={item.imgUrl} style={{ width: '100%', height: '250px', objectFit: 'contain' }} alt="img-staff" />
                                </div>
                            </div>
                        )
                    })
                }
            </Slider >
        </div >
    );
};

const NextArrow = (props) => {
    const { onClick } = props;
    return <img className={styles.btn_next} src='/assets/images/btn_next.png' onClick={onClick} alt='btnNext' />;
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return <img className={styles.btn_previous} src='/assets/images/btn_previous.png' onClick={onClick} alt='btnPrevious' />;
};

export default CarouselImg;

import { useEffect, useState } from "react";
import { fetchData } from "../utils/api";
import apiConfig from "../constants/apiConfig";

const useDataCompany = () => {
    const [dataCompany, setDataCompany] = useState([])
    useEffect(() => {
        const fetchDataAndSetState = async () => {
            try {
                const data = await fetchData(apiConfig.infoCompany.path, apiConfig.infoCompany.requestOption);
                setDataCompany(data.data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchDataAndSetState();
    }, []);
    return dataCompany
}
export default useDataCompany
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import styles from '../styles/Carousel.module.css';
import useDataEmployee from '../data/dataEmployee'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const Carousel = () => {
    const dataStaff = useDataEmployee()
    const autoplay = false
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoplay) {
                sliderRef.current.slickNext();
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [autoplay]);

    const sliderRef = React.useRef(null);
    const onClickSocial = (link) => {
        window.open(`${link}`, '_blank');
    }
    return (
        <div className={styles.container_demo}>
            <Slider ref={sliderRef} {...settings} className={styles.demo}>
                {
                    dataStaff.map((item) => {
                        return (
                            <div div key={item.id} className={styles.container} >
                                <div className={styles.container_staff}>
                                    <div className={styles.img_staff}>
                                        <img src={item.image} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="img-staff" />
                                    </div>
                                    <div className={styles.social}>
                                        {
                                            item.linkedin ? <div className={styles.item_social} onClick={() => onClickSocial(item.linkedin)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
                                                    <path d="M33.2741 0.914062L3.15763 0.914062C1.51202 0.914062 0.0742187 2.09813 0.0742187 3.72442L0.0742186 33.9077C0.0742185 35.5429 1.51202 37 3.15763 37L33.2652 37C34.9197 37 36.1602 35.5332 36.1602 33.9077L36.1602 3.72442C36.1698 2.09813 34.9197 0.914062 33.2741 0.914062ZM11.2601 30.9935L6.09042 30.9935L6.09042 14.9199L11.2601 14.9199L11.2601 30.9935ZM8.85406 12.4761L8.817 12.4761C7.16253 12.4761 6.09123 11.2445 6.09123 9.70276C6.09123 8.13286 7.19072 6.93027 8.88225 6.93027C10.5738 6.93026 11.6088 8.124 11.6459 9.70276C11.6451 11.2445 10.5738 12.4761 8.85406 12.4761ZM30.1536 30.9935L24.984 30.9935L24.984 22.2048C24.984 20.0992 24.2317 18.6606 22.3613 18.6606C20.9324 18.6606 20.0866 19.6272 19.7104 20.5688C19.5695 20.9071 19.5316 21.3679 19.5316 21.8383L19.5316 30.9935L14.362 30.9935L14.362 14.9199L19.5316 14.9199L19.5316 17.1568C20.284 16.0855 21.4592 14.5438 24.1938 14.5438C27.5873 14.5438 30.1544 16.7806 30.1544 21.6031L30.1536 30.9935Z" fill="#1C1F35" />
                                                </svg>
                                            </div> : null
                                        }
                                        {
                                            item.twitter ? <div className={styles.item_social} onClick={() => onClickSocial(item.twitter)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="37" viewBox="0 0 46 37" fill="none">
                                                    <path d="M45.3895 5.18987C43.6963 5.92508 41.9048 6.40944 40.0715 6.62767C41.9981 5.50034 43.447 3.70938 44.1463 1.59069C42.3248 2.65502 40.3354 3.40181 38.2631 3.79906C37.3905 2.8852 36.3411 2.1582 35.1785 1.66224C34.016 1.16629 32.7647 0.911744 31.5007 0.914078C26.383 0.914078 22.2414 4.99253 22.2414 10.0201C22.2378 10.7194 22.318 11.4167 22.4803 12.0969C18.8105 11.925 15.2171 10.9895 11.9298 9.35032C8.64261 7.71111 5.73397 5.40428 3.39002 2.57741C2.5677 3.96269 2.13282 5.54325 2.13081 7.15392C2.13081 10.3114 3.77935 13.1024 6.26861 14.7376C4.79379 14.7026 3.3492 14.3125 2.05746 13.6005L2.05746 13.7133C2.05746 18.13 5.25485 21.8044 9.48668 22.6408C8.69089 22.8527 7.87084 22.9601 7.04726 22.9603C6.46287 22.9613 5.87981 22.9046 5.30657 22.7911C6.48302 26.4091 9.90704 29.0403 13.963 29.1155C10.6672 31.6537 6.6209 33.0255 2.45996 33.0154C1.72138 33.0143 0.983485 32.9704 0.25 32.8839C4.48294 35.5849 9.4034 37.0136 14.4257 36.9999C31.4809 36.9999 40.7985 23.12 40.7985 11.082C40.7985 10.6873 40.7881 10.2926 40.7693 9.90734C42.5778 8.62184 44.1424 7.02434 45.3895 5.18987Z" fill="#1C1F35" />
                                                </svg>
                                            </div> : null
                                        }
                                        {
                                            item.facebook ? <div className={styles.item_social} onClick={() => onClickSocial(item.facebook)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="37" viewBox="0 0 40 37" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M39.2394 19.066C39.2394 9.04193 30.5619 0.914062 19.86 0.914062C9.15798 0.914062 0.480469 9.04193 0.480469 19.066C0.480469 28.1258 7.56609 35.6354 16.8319 36.9984L16.8319 24.3147L11.91 24.3147L11.91 19.066L16.8319 19.066L16.8319 15.0669C16.8319 10.5184 19.7259 8.00386 24.152 8.00386C26.2725 8.00386 28.4907 8.3588 28.4907 8.3588L28.4907 12.8263L26.0458 12.8263C23.6398 12.8263 22.8871 14.225 22.8871 15.6625L22.8871 19.066L28.2615 19.066L27.4032 24.3147L22.888 24.3147L22.888 37C32.1538 35.6378 39.2394 28.1282 39.2394 19.066Z" fill="#1C1F35" />
                                                </svg>
                                            </div>
                                                : null
                                        }
                                        {
                                            item.instagram ? <div className={styles.item_social} onClick={() => onClickSocial(item.instagram)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
                                                    <path d="M26.8908 3.92096C28.9611 3.92691 30.9448 4.72093 32.4087 6.12963C33.8727 7.53832 34.6978 9.44721 34.704 11.4394L34.704 26.4747C34.6978 28.4669 33.8727 30.3757 32.4087 31.7844C30.9448 33.1931 28.9611 33.9872 26.8908 33.9931L11.2661 33.9931C9.1958 33.9872 7.21208 33.1931 5.74816 31.7844C4.28424 30.3757 3.45908 28.4669 3.4529 26.4747L3.4529 11.4394C3.45908 9.44721 4.28424 7.53832 5.74816 6.12963C7.21208 4.72093 9.1958 3.92691 11.2661 3.92096L26.8908 3.92096ZM26.8908 0.914062L11.2661 0.914062C5.25008 0.914062 0.328125 5.65034 0.328125 11.4394L0.328125 26.4747C0.328125 32.2637 5.25008 37 11.2661 37L26.8908 37C32.9068 37 37.8288 32.2637 37.8288 26.4747L37.8288 11.4394C37.8288 5.65034 32.9068 0.914062 26.8908 0.914062Z" fill="#1C1F35" />
                                                    <path d="M29.2344 11.4399C28.7709 11.4399 28.3177 11.3077 27.9323 11.0598C27.5469 10.812 27.2464 10.4598 27.0691 10.0477C26.8917 9.63555 26.8452 9.18207 26.9357 8.74457C27.0261 8.30707 27.2493 7.9052 27.5771 7.58978C27.9049 7.27436 28.3225 7.05956 28.7772 6.97254C29.2318 6.88551 29.7031 6.93018 30.1314 7.10088C30.5596 7.27158 30.9257 7.56066 31.1832 7.93155C31.4408 8.30245 31.5782 8.7385 31.5782 9.18457C31.5789 9.48093 31.5187 9.7745 31.4012 10.0484C31.2836 10.3223 31.111 10.5712 30.8932 10.7808C30.6754 10.9903 30.4168 11.1564 30.1321 11.2696C29.8475 11.3827 29.5424 11.4406 29.2344 11.4399ZM19.0783 12.943C20.3145 12.943 21.5229 13.2957 22.5508 13.9566C23.5787 14.6175 24.3798 15.5569 24.8529 16.6559C25.326 17.7549 25.4497 18.9642 25.2086 20.131C24.9674 21.2977 24.3721 22.3694 23.498 23.2105C22.6238 24.0517 21.5101 24.6245 20.2977 24.8566C19.0852 25.0887 17.8285 24.9696 16.6864 24.5143C15.5443 24.0591 14.5681 23.2882 13.8813 22.2991C13.1945 21.31 12.8279 20.1471 12.8279 18.9576C12.8297 17.3629 13.4888 15.8341 14.6606 14.7065C15.8323 13.5789 17.4211 12.9447 19.0783 12.943ZM19.0783 9.93609C17.2241 9.93609 15.4115 10.4652 13.8697 11.4565C12.328 12.4478 11.1264 13.8567 10.4168 15.5052C9.70719 17.1537 9.52153 18.9676 9.88327 20.7176C10.245 22.4676 11.1379 24.0751 12.449 25.3367C13.7602 26.5984 15.4307 27.4576 17.2493 27.8057C19.0679 28.1538 20.9529 27.9752 22.666 27.2923C24.3791 26.6095 25.8433 25.4532 26.8734 23.9696C27.9036 22.4861 28.4534 20.7419 28.4534 18.9576C28.4534 16.5649 27.4657 14.2703 25.7075 12.5784C23.9493 10.8866 21.5647 9.93609 19.0783 9.93609Z" fill="#1C1F35" />
                                                </svg>
                                            </div>
                                                : null
                                        }
                                    </div>
                                    <div className={styles.bgr_info}>
                                        <div className={styles.name_staff}>
                                            {item.name}
                                        </div>
                                        <div className={styles.position_staff}>
                                            {item.role}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider >
        </div >
    );
};

const NextArrow = (props) => {
    const { onClick } = props;
    return <img className={styles.btn_next} src='/assets/images/btn_next.png' onClick={onClick} alt='btnNext' />;
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return <img className={styles.btn_previous} src='/assets/images/btn_previous.png' onClick={onClick} alt='btnPrevious' />;
};

export default Carousel;

import styles from '../../styles/News/index.module.css'
import Slider from '../HomePage/slider'
import TabNews from './TabNews'
import useDataCategoryNews from '../../data/dataCategory'
import useDataDetail from '../../data/dataDetail'
import apiConfig from '../../constants/apiConfig'
import { Link } from 'react-router-dom'
import { formatDateUS } from '../../hook/dateFormat'
import { useEffect, useState } from 'react'
const NewsPage = () => {
    const [viewportWidth, setViewportWidth] = useState();
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    }
    useEffect(() => {
        const updateViewportWidth = () => {
            const width = window.innerWidth;
            setViewportWidth(width);
        };
        updateViewportWidth();
        window.addEventListener('resize', updateViewportWidth);
        return () => {
            window.removeEventListener('resize', updateViewportWidth);
        };
    }, [viewportWidth]);
    const dataNews = useDataDetail(apiConfig.news.featuredNews.path, apiConfig.news.featuredNews.requestOption)
    const tabData = useDataCategoryNews();
    return (
        <div className={styles.div_news}>
            <Slider
                isShowTitle={true}
                isSlider={false}
                imgUrl={'/assets/images/bgrDocument.jpg'}
                // title={'Tin tức'}
                content={'Hãy chia sẽ với chúng tôi !'} />
            <div></div>
            <div className={styles.highlight_news}>
                <div className={styles.title}>
                    Tin tức nổi bật
                </div>
                <div className={styles.container_highlightNews}>
                    {
                        dataNews[0] ? (
                            <div className={styles.newsContainer} key={dataNews[0].id}>
                                <div className={styles.firstNews}>
                                    <div className={styles.firstNewsImg}>
                                        <Link to={`/detailNews/${dataNews[0].id}`} reloadDocument>
                                            <img src={dataNews[0].thumbnailImage} alt='imgNews' style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                                        </Link>
                                    </div>
                                    <div className={styles.firstNewsInfo}>
                                        <div className={styles.firstNewsDate}>
                                            <img src='/assets/images/date_icon.png' style={{ width: '40px', objectFit: 'contain' }} alt='iconDate' />
                                            <div style={{ width: 'max-content' }}>
                                                <div className={styles.news_date}>
                                                    {formatDateUS(dataNews[0].dateCreated).split("/")[0]}
                                                </div>
                                                <div className={styles.news_month}>
                                                    tháng {formatDateUS(dataNews[0].dateCreated).split("/")[1]}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.firstNewsContent} dangerouslySetInnerHTML={{ __html: truncateText(dataNews[0].content, 200) }}>
                                        </div>
                                    </div>
                                    {/* <div className={styles.btn_seeMore}>
                                        <Link to={`/detailNews/${dataNews[0].id}`} reloadDocument>Xem thêm</Link>
                                    </div> */}
                                </div>
                                <div className={styles.orderNewsContainer}>
                                    {dataNews.slice(1, 4).map((item) => (
                                        <Link to={`/detailNews/${item.id}`} key={item.id} reloadDocument>
                                            <div className={styles.orderNews}>
                                                <div className={styles.orderNewsDate}>
                                                    <img src='/assets/images/date_icon.png' style={{ width: '40px', objectFit: 'contain' }} alt='iconDate' />
                                                    <div style={{ width: 'max-content' }}>
                                                        <div className={styles.news_date}>
                                                            {formatDateUS(item?.dateCreated).split('/')[0]}
                                                        </div>
                                                        <div className={styles.news_month}>
                                                            tháng {formatDateUS(item?.dateCreated).split('/')[1]}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.orderNewsInfo}>
                                                    <div className={styles.orderNewsImg}>
                                                        <img src={item.thumbnailImage} alt='imgNews' style={{ width: '200px', height: '100%', objectFit: 'contain' }} />
                                                    </div>
                                                    <div className={styles.orderNewsContent} >
                                                        {item.title}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            <div className={styles.containerCategoryNews}>
                <TabNews categoryNewsData={tabData} />
            </div>
        </div>
    )
}

export default NewsPage
import { useState } from 'react';
import styles from '../../styles/PaginationPage/customerPagination.module.css'
import useDataCustomer from '../../data/dataCustomer';
const CustomerPagination = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const dataCustomer = useDataCustomer();
    const itemsPerPage = 16;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentNews = dataCustomer.slice(startIndex, endIndex);

    const totalPages = Math.ceil(dataCustomer.length / itemsPerPage);
    const generatePageArray = () => {
        const pagesToShow = 2; // Số trang muốn hiển thị ở đầu và cuối
        const pageArray = [];

        for (let i = 1; i <= totalPages; i++) {
            if (i <= pagesToShow || i > totalPages - pagesToShow || (i >= currentPage - 1 && i <= currentPage + 1)) {
                pageArray.push(i);
            } else if (pageArray[pageArray.length - 1] !== '......') {
                pageArray.push('......');
            }
        }

        return pageArray;
    };
    const pageArray = generatePageArray();
    const handlePageChange = (newPage) => {
        if (newPage === '......') {
            return;
        }
        setCurrentPage(newPage);
    };
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePageChange(currentPage + 1);
        }
    };

    return (
        <div className={styles.containerCustomer}>
            {currentNews.map((item) => (
                <div key={item.id} className={styles.item_Customer}>
                    <div className={styles.imgCustomer}>
                        <img src={item.logo} style={{ width: '100%', height: '200px', objectFit: 'contain' }} alt='imgUser' />
                    </div>
                </div>
            ))}

            <div className={styles.containerPagination} style={{ marginBottom: '10px' }}>
                <span onClick={handlePreviousPage} className={`${styles.btn_previous} ${currentPage === 1 ? styles.disable : ''}`}>
                    &lt; Previous
                </span>
                {pageArray.map((page, index) => (
                    <span
                        key={index}
                        onClick={() => handlePageChange(page)}
                        className={`${styles.itemPagination} ${currentPage === page ? styles.activePage : ''} ${page === '......' ? styles.dot : ''}`}
                    >
                        {page}
                    </span>
                ))}
                <span onClick={handleNextPage} className={`${styles.btn_next} ${currentPage === totalPages ? styles.disable : ''}`}>
                    Next &gt;
                </span>
            </div>
        </div>
    );
};

export default CustomerPagination;
